var windowH = jQuery(window).outerHeight();
var headerHeight = jQuery('.site-header').outerHeight();

/*===== Document Ready =======*/
jQuery(document).ready(function() {






    /*************** Touch ****************/
    if (!("ontouchstart" in document.documentElement)) {
        document.documentElement.className += " no-touch";
    } else {
        document.documentElement.className += " touch";
    }

    /*************** services **************/

    if(jQuery(window).width() < 767) {
        jQuery('.service-item .service-contect-desc').hide('slow');
        jQuery('.service-item h2').click( function(){
            jQuery('.service-item h2').removeClass('active');
            jQuery(this).toggleClass('active');
            jQuery('.service-item .service-contect-desc').hide(400);
            jQuery(this).next().slideToggle();
        });

    }

	/*************** Menu Setting **************/

	if(jQuery(window).width() > 767){
		jQuery(".touch .main-navigation  .menu li > a").on("click touchstart", function (event) {
		if (!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0) {
			event.preventDefault();
			event.stopPropagation();
			jQuery(this).parent().addClass("open").siblings().removeClass("open");
			}
		});
    }

    jQuery(".main-navigation .menu li.menu-item-has-children > a + span.menu-arrow").on("click touchstart", function (event) {
        if (jQuery(this).parent().has("ul").length > 0) {
            event.preventDefault();
		    event.stopPropagation();
            jQuery(this).parent().toggleClass("open").siblings().removeClass("open");
        }
    });


    jQuery('.menu-icon').on('click', function () {
        jQuery('body').toggleClass('open-menu');
    });

    jQuery('.nav-icon').on('click', function () {
        jQuery('body').toggleClass('open-menu');
    });

    jQuery(document).on('click', function () {
        jQuery('body').removeClass('open-menu');
    });
    jQuery('.menu-icon').on('click', function (e) {
        e.stopPropagation();
    });

    jQuery('.nav-icon').on('click', function (e) {
        e.stopPropagation();
    });

    jQuery('.touch .service-item').on("click", function (event) {
	if (!jQuery(this).parent().hasClass("open")) {
        event.preventDefault();
        event.stopPropagation();
        jQuery(this).parent().addClass("open").siblings().removeClass("open");
        }
    });


    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
      jQuery('body').addClass("safari");
      jQuery('body').addClass("chrome");
    }



});



/*=================== window Load ===================================*/
jQuery(window).load(function() {
    global();
    heightGlobal();
});

/*========================== window Resize ============================*/
jQuery(window).resize(function(){
    global();
    heightGlobal();
});

/*===== Global function =======*/
function global() {

}


/*===== global Height =======*/
function heightGlobal(){
    equalheight ('.service-box .service-item');
}


/*===== Equal Height =======*/
equalheight = function (container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            jQueryel,
            topPosition = 0;
        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight('auto');
            topPostion = jQueryel.offset().top;
            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].innerHeight(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = jQueryel.innerHeight();
                rowDivs.push(jQueryel);
            }
            else {
                rowDivs.push(jQueryel);
                currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    }
    else {
        jQuery(container).height('auto');
    }
};
